<template>
  <b-img
    class="golog-logo"
    fluid
    v-bind="mainProps"
    :src="imgUrl"
    alt="Logo"
  />
</template>
<script>
/* eslint-disable global-require */
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  data() {
    return {
      mainProps: {
        width: 75, height: 75, class: 'm1',
      },
      logoImg: require('@/assets/images/logo/logo.png'),
    }
  },
  computed: {
    imgUrl() {
      return this.logoImg
    },
  },
}
</script>
<style scoped>
.brand-logo img{
  filter: grayscale(0%) brightness(100%);
}
</style>